import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Service
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ConfigService } from '../../../dependency-issues';
// Model
import { UserStateModel } from '../../../models/user/user-state.model';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private oidcSecurityService: OidcSecurityService,
        private httpClient: HttpClient,
        private configService: ConfigService
    ) { }

    login() {
        this.oidcSecurityService.authorize();
    }

    completeAuthentication() {
        return this.oidcSecurityService.checkAuth();
    }

    getState() {
        return this.oidcSecurityService.getState();
    }

    isLoggedIn() {
        return this.oidcSecurityService.isAuthenticated;
    }

    logout() {
        this.oidcSecurityService.logoff();
    }

    getClaims() {
        return this.oidcSecurityService.getUserData();
    }

    getAuthorizationHeaderValue() {
        return `Bearer ${this.oidcSecurityService.getAccessToken()}`;
    }

    getUserStateFromService(username: string): Observable<UserStateModel> {
        return this.httpClient.get<UserStateModel>(
            `${this.configService.api.dataMasterApi}usersettings/getuserstate/${username}`
        );
    }
}
