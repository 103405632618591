
/**
 * Reference to the Config File Model
 */
export class ConfigFileModel {
	production?: boolean;
    environment?: string;
    userNotificationMock?: boolean;
    defaultTheme?: string;
    dashboardLayout?: string;
    classDataApiUrl?: string;
    authUrl?: string;
    dmWebUrl?: string;
    newsFeedUrl?: string;
    dataMasterApi?: string;
    userNotificationUrl?: string;
    fusebillPortalUrl?: string;
    public options?: ConfigFileApiModel[];
    public api?: ConfigFileApiModel[];
	public oidc_configuration?: IDSConfig;
}

export class ConfigFileApiModel {
    [key:string]: string;
}

export class IDSConfig {
	authority?: string;
    redirectUrl?: string;
    clientId?: string;
    responseType?: string;
    scope?: string;
    postLogoutRedirectUri?: string;
    enableIdTokenExpiredValidationInRenew?: boolean;
    disableRefreshIdTokenAuthTimeValidation?: boolean;
    silentRenew?: boolean;
    silentRenewUrl?: string;
    useRefreshToken?: boolean;
    maxIdTokenIatOffsetAllowedInSeconds?: number;
}
