import { Injectable } from '@angular/core';
import { firstValueFrom, isObservable, lastValueFrom, Observable, of, Subject } from 'rxjs';
// service and model
import { DataSource } from '../models/data-source/data-source';
import { DataSourceCredentials } from '../models/settings/data-source-credentials.model';
import { UserStateModel } from '../models/user/user-state.model';
import { UserSettingsService } from './user/user-settings.service';
import { UserStateService } from './user/user-state.service';
import { UserApiService } from './user/user.service';

declare const Zone: any;
@Injectable({
  providedIn: 'root',
})
export class DataSourceService {

    get userState(): UserStateModel {
        return this.userStateService.user;
    }

  private userDataSources: DataSource[];
  private dataSources: DataSource[];

  private _userDataSourcesChangedSubject = new Subject<DataSource[]>();
  userDataSourcesChanged = this._userDataSourcesChangedSubject.asObservable();

  constructor(
    private userStateService: UserStateService,
    private userApiService: UserApiService,
    private userSettingService: UserSettingsService
  ) { }

  async getUserDataSources(): Promise<DataSource[]> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject)=>{
      if (this.userDataSources) {
        resolve(this.userDataSources);
        return;
      }

      if (!this.userState) {
          resolve(null);
          return;
      }

      if (this.userState.isInternal) {
        let dataSources : DataSource[];
         await this.getInternalDataSources(this.userState.user.userName).then((data)=>{
           dataSources = data;
         });
         resolve(dataSources);
        return;
      }

      const planCodes = this.getPlanCodes(this.userState);

      if (!planCodes) {
        resolve(null);
        return;
      }

      const dataSources : DataSource[] = await lastValueFrom(this.userApiService.getUserDataSources(planCodes));
      this.userDataSources = dataSources;
      resolve(dataSources);
    
    });
  }

  private getPlanCodes(userState: UserStateModel): string[] {
    const subscriptions = userState?.subscriptions;

    if (!subscriptions || subscriptions.length == 0) return null;

    if (!this.userState.userHasPropertySearchAppAccess) {
      return null;
    }

    let planCodes = subscriptions.map((a) => a.planCode);

    const productCodes = subscriptions
      .map((a) => a.productCodes)
      .reduce(function (a, b) {
        return a.concat(b);
      });

    if (!productCodes || productCodes.length == 0) return planCodes;

    planCodes = planCodes.concat(productCodes)

    return planCodes.filter((n, i) => planCodes.indexOf(n) === i);;
  }

  private async getInternalDataSources(userName: string): Promise<DataSource[]> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject)=>{
      const userSettings = await lastValueFrom(this.userSettingService.getUserSettings(userName));
        if (
          !userSettings.selectedDataSourceIds ||
          userSettings.selectedDataSourceIds.length === 0
        ) {
          resolve(null);
          return;
        }

        const userDataSources= await lastValueFrom(this.userApiService
          .getDataSourcesByIds(userSettings.selectedDataSourceIds));
              this.userDataSources = userDataSources;
        resolve(userDataSources);
        return;
    });
  }

  getDataSources(): Promise<DataSource[]> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject)=>{
      if (!this.dataSources) {
        this.dataSources = await lastValueFrom(this.userApiService.getDataSources());
      }
      resolve(this.dataSources);
    });
  }

  getMLSDataSourceIds(): number[] {
    this.getUserDataSources();
    if (!this.userDataSources) return [];
    return this.userDataSources.reduce(
      (reducedArray, ds) => (
        ds.dataSourceGroup.commonName === 'Mls' && reducedArray.push(ds.id),
        reducedArray
      ),
      []
    );
  }

  getMLSDataSources(): DataSource[] {
    this.getUserDataSources();
    if (!this.userDataSources) return [];
    return this.userDataSources.reduce(
      (reducedArray, ds) => (
        ds.dataSourceGroup.commonName === 'Mls' && reducedArray.push(ds),
        reducedArray
      ),
      []
    );
  }

  getPRDataSources(): DataSource[] {
    this.getUserDataSources();
    if (!this.userDataSources) return [];
    return this.userDataSources.reduce(
      (reducedArray, ds) => (
        ds.dataSourceGroup.commonName === 'PublicRecords' && reducedArray.push(ds),
        reducedArray
      ),
      []
    );
  }

  getPRDataSourceIds(): number[] {
    this.getUserDataSources();
    if (!this.userDataSources) return [];
    return this.userDataSources.reduce(
      (reducedArray, ds) => (
        ds.dataSourceGroup.commonName === 'PublicRecords' &&
        reducedArray.push(ds.id),
        reducedArray
      ),
      []
    );
  }

  getUserDataSourceIds(): number[] {
    this.getUserDataSources();
    if (!this.userDataSources) return [];

    return this.userDataSources.reduce(
      (reducedArray, ds) => (reducedArray.push(ds.id), reducedArray),
      []
    );
  }

  getUserPlatMapDatSourceIds(): number[] {
    this.getUserDataSources();
    if (!this.userDataSources) return [];

    return this.userDataSources.reduce(
      (reducedArray, ds) => (
        ds.dataSourceGroup.commonName === 'PlatService' &&
        reducedArray.push(ds.id),
        reducedArray
      ),
      []
    );
  }

  getDataSourceCredentials(
    dataSourceIds: number[]
  ): Observable<DataSourceCredentials[]> {
    return this.userApiService.getDataSourceCredentials(dataSourceIds);
  }

  updateUserDataSources(dataSources: DataSource[]) {
    if (!dataSources) {
      return;
    }

    //by splicing and pushing, the components subscribed to this.userdatasources will receving the update on these datasources
    this.userDataSources?.slice().forEach((userDataSource) => {
      if (dataSources.some((x) => x.id === userDataSource.id)) {
        return;
      }
      const index = this.userDataSources.indexOf(userDataSource);
      this.userDataSources.splice(index, 1);
    });

    if (!this.userDataSources) {
      this.userDataSources = [];
    }

    dataSources.forEach((a) => {
      if (!this.userDataSources.some((b) => b.id === a.id)) {
        this.userDataSources.push(a);
      }
    });

    this._userDataSourcesChangedSubject.next(this.userDataSources);
  }

  filterMlsDataSources(dataSources: DataSource[]) {
    return dataSources?.filter((a) => a.dataSourceGroup.commonName == 'Mls');
  }

  filterPrDataSources(dataSources: DataSource[]) {
    return dataSources?.filter(
      (a) => a.dataSourceGroup.commonName == 'PublicRecords'
    );
  }

  async waitFor<T>(prom: Promise<T> | Observable<T>): Promise<T> {
    if (isObservable(prom)) {
      prom = firstValueFrom(prom);
    }
    const macroTask = Zone.current
      .scheduleMacroTask(
        `WAITFOR-${Math.random()}`,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { },
        {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    return prom.then((p: T) => {
      macroTask.invoke();
      return p;
    });
  }
}
