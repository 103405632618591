import { AuthUser } from './auth-user';
import { SubscriptionModel } from '../subscription.model';
import { UserShareSetting } from './user-share-setting.model';

export class UserStateModel {
    //dm service
    userId: number; //this is only used to retreive data from the db on startup
    dataSourceIds: number[];//should we use commonNames instead? plancodes?
    //UserFormatProfile: UserFormatProfile; not implemented in dm6
    //can be retreived by one call
    Permissions: string[];
    isImpersonating: boolean;
    isInternal: boolean;
    isAssistant: boolean;
    isExpressVersion: boolean;
    isProVersion: boolean; // do we need this if we have the above? if we do need it, do we need one type instead of 2 booleans
    userHasPropertySearchAppAccess: boolean; // Moved Value
    appraisers: AuthUser[];
    userShareSetting: UserShareSetting;
    user: AuthUser; //auth
    supportUsername: string;
    fusebillSingleSignOnToken: string;
    //fusebillCustomerId: number; // same as user.fusebillId?
    //planCodes: string[];
    //productCodes: string[];//IsIncluded
    subscriptions: SubscriptionModel[];
    iconPath?: string;

    constructor() {
        this.userId = null;
        this.dataSourceIds = [];
        this.Permissions = [];
        this.isImpersonating = null;
        this.isInternal = null;
        this.isAssistant = null;
        this.isExpressVersion = null;
        this.isProVersion = null;
        this.userHasPropertySearchAppAccess = null;
        this.appraisers = [];
        this.userShareSetting = new UserShareSetting();
        this.user = new AuthUser();
        this.supportUsername = '';
        this.fusebillSingleSignOnToken = '';
        this.subscriptions = [];
    }
}
