export class AuthUser {
    fusebillId: number;
    firstName: string;
    lastName: string;
    userName: string;

    constructor() {
        this.fusebillId = null;
        this.firstName = '';
        this.lastName = '';
        this.userName = '';
    }
}
